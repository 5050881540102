module.exports = {
  'title': 'Lucas Van Remoortere',
  'homepageTitle': 'Lucas Van Remoortere | Web Developer uit Antwerpen',
  'header.title': 'Lucas Van Remoortere',
  'header.subTitle': 'Web Developer uit Antwerpen',
  'home': 'Home',
  'about': 'Over',
  'blog': 'Blog',
  'all': 'Alles',
  'posts': 'Posts',
  'posts.seeMore': 'Bekijk alle posts →',
  'tags': 'Post Tags',
  'tags.allTagsLink': 'Bekijk alle tags',
  'tags.nPostsTaggedWith': `{nPosts, number} {nPosts, plural,
      one {post}
      other {posts}
    } com a tag`,

  'contact': 'Contact',
  'call': 'Plan een gesprek',
  'index.featured': 'Uitgelichte posts',
  'index.stories': 'Alle posts'
};
